ul li {
  color: #0093ff;
}

::marker {
  list-style-position: inside;
}

ul li {
  border-bottom: 1px solid rgba(199, 199, 199, 0.5);
  // padding: 0;
}

ul li:last-child {
  border: none;
}

ul li a {
  text-decoration: none;
  color: #2b334d;
}

ul li a:hover {
  text-decoration: none;
  color: #000;
}

input[type='search'] {
  background: rgb(247, 248, 250) url(../../images/search-icon.svg) 1rem center no-repeat;
}
