@mixin tr() {
  transition: 0.15s ease-in-out;
}

@mixin scrollbar($width, $color: #dadada) {
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:active {
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-track:hover {
    background: transparent;
  }

  &::-webkit-scrollbar-track:active {
    background: transparent;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

@mixin placeholderColor($color) {
  &::placeholder {
    color: $color;
    opacity: 1;
    font-style: italic;
    font-weight: 100;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color;
    font-weight: 100;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color;
    font-weight: 100;
  }

  &:focus::placeholder {
    color: transparent;
  }
}
