.converter-page {
  .page-main-title {
    p {
      margin-bottom: 20px;
    }

    .ads {
      margin: 0;
    }
  }
  .state-component-holder-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper {
      max-width: 900px;
      width: 100%;
      height: 300px;
      display: grid;

      .state-component-holder {
        grid-area: 1 / 1;
        padding: 30px;
        border-radius: 50px;
        border: 1px solid #f2f4fd;

        .converter-state {
          height: 100%;
        }

        .task-state {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;

          h5 {
            font-size: 20px;
          }

          .file-name {
            font-size: 14px;
            max-width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .task-state-main {
            flex-grow: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

          .progress {
            border-radius: 50px;
            .progress-bar {
              border-radius: inherit;
            }
          }

          .light-btn {
            flex-shrink: 0;
            height: 54px;
            font-size: 14px;
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 50px;
            margin-bottom: 10px;
            &:hover {
              color: #111;
              background: #fff;
              box-shadow: 8px 8px 16px 0px #eee;
            }
          }

          .btn-primary {
            margin-top: 20px;
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 10px;
            padding: 10px 50px;
            border-radius: 12px;
            height: 58px;
          }

          @media (max-width: 991px) {
            .btn-primary {
              padding: 10px 40px;
              margin-top: 0;
              margin-bottom: 0;
              font-size: 14px;
              height: 54px;
            }
          }

          @media (max-width: 600px) {
            h5 {
              font-size: 18px;
            }
            .file-name {
              font-size: 14px;
            }
          }
        }

        .task-builder {
          border-radius: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          border: 2px dashed rgb(228 232 249);
          background: #f5f7fc;

          h5 {
            font-family: 'DM Sans', sans-serif;
            font-weight: 500;
            font-size: 20px;
            color: rgba(0, 0, 0, 0.5);
            margin-bottom: 10px;
            margin-top: 20px;
          }

          p {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.46);
          }

          .file-drop-overlay {
            position: fixed;
            z-index: 1000000;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: none;
            background-color: #f6fbff;
            opacity: 0.98;

            * {
              pointer-events: none;
            }

            img {
              width: 140px;
              height: auto;
              margin-bottom: 0px;
            }

            &.open {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }
          }

          @media (max-width: 600px) {
            border-radius: 24px;
          }
        }

        .task-success {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 0;
          img {
            margin-top: -21px;
            margin-right: -13px;
          }

          h5 {
            margin-top: -27px;
            margin-bottom: 8px;
          }

          .file-name {
            margin-top: -25px;
            margin-bottom: 25px;
          }

          p {
            margin: 0;
            margin-bottom: 10px;
          }

          .btn-primary {
            margin: 0;
            margin-bottom: 10px;
          }

          .another-video {
            margin: 0;
            span {
              cursor: pointer;
              color: #c0c0c0;
              text-decoration: underline;
            }
          }

          @media (max-width: 600px) {
            h5 {
              font-size: 18px;
              margin-top: -33px;
              margin-bottom: 3px;
            }
            .file-name {
              margin-bottom: 0;
            }
            .btn-primary {
              margin-top: 6px;
              margin-bottom: 5px;
            }
          }
        }

        .task-failure {
          .task-state-main {
            img {
              width: 80px;
              height: auto;
            }

            p {
              margin-top: 20px;
              font-size: 16px;
            }
          }
        }

        .task-progress {
          .task-icon {
            margin-top: -20px;
          }

          .progress {
            height: 12px;
            max-width: 300px;
            width: 100%;
          }

          .progress-text {
            margin-top: 20px;
            font-size: 15px;

            img {
              margin-right: 8px;
              width: 20px;
            }

            span {
              margin-left: 8px;
            }
          }

          @media (max-width: 600px) {
            .progress-text {
              margin-top: 14px;
              font-size: 14px;
            }
          }
        }
      }

      .state-component-holder-back {
        grid-area: 1 / 1;
        height: 100%;
        margin: 0 40px;
        border-radius: 0 0 32px 32px;
        margin-top: 30px;
      }

      .state-component-holder-back-2 {
        margin: 0 80px;
        height: 100%;
        border-radius: 0 0 28px 28px;
        margin-top: 58px;
      }
    }

    @media (max-width: 991px) {
      .wrapper {
        height: 320px;
        .state-component-holder {
          padding: 30px;
          border-radius: 40px;
        }

        .state-component-holder-back {
          margin: 0 30px;
          border-radius: 0 0 28px 28px;
          margin-top: 24px;
        }

        .state-component-holder-back-2 {
          margin: 0 62px;
          border-radius: 0 0 24px 24px;
          margin-top: 44px;
        }
      }
    }

    @media (max-width: 600px) {
      .wrapper {
        height: 260px;
        .state-component-holder {
          padding: 20px;
          border-radius: 32px;
        }

        .state-component-holder-back {
          margin: 0 20px;
          border-radius: 0 0 24px 24px;
          margin-top: 18px;
        }

        .state-component-holder-back-2 {
          margin: 0 44px;
          border-radius: 0 0 20px 20px;
          margin-top: 32px;
        }
      }
    }
  }

  .state-component-holder-bg {
    //background-color: rgb(247, 248, 250);
    background: rgb(131, 58, 180);
    background: linear-gradient(0deg, rgb(247, 248, 250) 0%, rgb(247, 248, 250) 50%, white 50%, white 100%);
  }

  .format-steps-container {
    background: #f7f8fa;
    background: linear-gradient(0deg, white 0%, white 42px, #f7f8fa 42px, #f7f8fa 100%);
    margin-top: -160px;
    padding-top: 96px;

    .container {
      max-width: 1160px;
    }

    h2 {
      margin-top: 180px;
      margin-bottom: 30px;
      font-size: 30px;
    }

    .steps-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      .step-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        box-shadow: 60px 60px 180px rgba(27, 35, 61, 0.07);
        border-radius: 20px;
        padding: 12px;
        background: white;

        .step-count {
          flex-shrink: 0;
          width: 60px;
          height: 60px;
          box-shadow: 7px 7px 20px rgba(27, 35, 61, 0.01);
          border-radius: 10px;
          color: #0093ff;
          background: rgba(0, 147, 255, 0.1);
          font-size: 26px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;

          &.step-orange {
            color: #ff8e00;
            background: rgba(255, 142, 0, 0.1);
          }

          &.step-green {
            color: #61bc5b;
            background: rgba(97, 188, 91, 0.1);
          }
        }

        p {
          margin: 0;
          margin-left: 20px;
        }
      }
    }

    @media (max-width: 992px) {
      h2 {
        margin-top: 170px;
        margin-bottom: 30px;
        font-size: 24px;
      }
      .steps-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        .step-wrapper {
          max-width: 500px;
          width: 100% !important;
        }
      }
    }

    @media (max-width: 600px) {
      h2 {
        font-size: 22px;
        margin-top: 140px;
        margin-bottom: 30px;
      }
    }
  }

  .format-details-container {
    grid-gap: 40px;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    .format-details-card {
      width: 100%;
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  .other-formats-container {
    margin-top: 50px;
    margin-bottom: 60px;
  }

  .ads {
    margin-bottom: 60px;
    margin-top: 60px;
  }

  @media (max-width: 768px) {
    margin-top: 20px !important;
  }

  @media (max-width: 600px) {
    margin-top: 60px !important;
  }
}
