.how-it-works-page {
  h1 {
    margin-bottom: 0px;
  }

  .icon {
    width: auto;
    height: 16px;
    margin: 0 2px;
    margin-bottom: 4px;
    display: inline-flex;
  }

  .page-content-container {
    & > p {
      margin-bottom: 40px;
    }
  }

  .collapsable-section {
    margin-top: 25px;
  }

  @media (max-width: 992px) {
    .page-content-container {
      & > p {
        margin-bottom: 30px;
      }
    }
  }
}
