@import './../mixins';

.format-details-card {
  @include tr;
  background: transparent;
  box-shadow: none;
  border: 1px solid rgba(27, 35, 61, 0.1);
  border-radius: 32px;

  .format-details-card__description {
    padding: 34px;
    h5 {
      font-weight: 700;
      font-size: 22px;
      display: flex;
      align-items: center;
      .format-details-card__description__img-wrapper {
        width: 100px;
        height: 70px;
        position: relative;
        flex-shrink: 0;
        img {
          position: absolute;
          height: 146px;
          width: auto;
          top: -29px;
          left: -24px;
        }

        .format-details-card__format-badge {
          font-size: 8px;
          position: absolute;
          top: 30px;
          left: 0px;
          color: #0093ff;
          text-align: center;
          width: 100%;
        }
      }

      span {
        margin-left: 25px;

        .marked {
          color: #0093ff;
          margin: 0;
        }
      }
    }

    h5 + p {
      margin-top: 20px;
      margin-bottom: 0;

      & + button {
        margin-top: 34px;
        font-weight: 500;
        font-size: 16px;
        font-family: 'DM Sans';
        font-style: normal;
        border: 1px solid rgba(27, 35, 61, 0.1);
        border-radius: 12px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          transform: rotate(180deg);
          height: 12px;
          width: auto;
        }

        &.collapsed {
          img {
            transform: rotate(0deg);
          }
        }

        &:hover {
          color: #0093ff;
        }
      }
    }

    @media (max-width: 600px) {
      padding: 26px;
      h5 {
        flex-direction: column;
        align-items: flex-start;
        font-size: 20px;

        span {
          margin-left: 0;
          margin-top: 18px;
        }
      }

      h5 + p {
        margin-top: 10px;

        & + button {
          margin-top: 15px;
        }
      }
    }
  }

  .format-details-card__formats-title {
    color: #1b233d;
    font-size: 18px;
    padding: 0;
  }

  .format-list-section {
    padding: 34px;

    @media (max-width: 600px) {
      padding: 26px;
    }
  }

  .format-details-card__formats-list {
    margin: 0;
    margin-top: 20px;
    list-style: none;
    width: 100%;
    padding: 0;

    li {
      font-weight: 500;
      font-size: 18px;
      width: 100%;
      color: #1b233d;
      line-height: 2;
      border-bottom: 1px solid rgba(27, 35, 61, 0.05);
      display: flex;
      padding: 0;
      &:first-child {
        margin-top: 0;
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;
        position: relative;
        padding: 7px 7px 7px 18px;
        font-size: 16px;
        font-weight: 100;

        &::before {
          content: '';
          background: #0093ff;
          border-radius: 50%;
          display: inline-block;
          width: 6px;
          height: 6px;
          margin-left: -18px;
          margin-right: 10px;
        }

        &::after {
          content: '';
          background: url(../../images/right-arrow.svg) no-repeat center;
          position: absolute;
          background-size: 12px;
          right: 0;
          top: 0;
          width: 20px;
          height: 100%;
          display: flex;
          align-items: center;
          //
          //margin-left: -15px;
          //margin-right: 10px;
        }

        &:hover {
          color: #0093ff;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &:hover {
    background: #fff;
    box-shadow: 0px 0px 180px rgba(27, 35, 61, 0.05);
    border-color: transparent;
  }

  @media (max-width: 600px) {
    border-radius: 24px;
  }
}
