//@import '~styles/media';

p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.8;
  color: rgba(27, 35, 61, 0.7);
}

.page-content-container {
  font-size: 16px;
  color: rgba(27, 35, 61, 0.7);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #1b233d;
    line-height: 1.8;
  }

  h1 {
    font-size: 44px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 14px;
    font-size: 20px;

    &:first-child {
      margin-top: 0px;
    }
  }

  h2 + h3 {
    margin-top: -8px;
  }

  &.bg {
    border-radius: 32px;
    background: #f7f8fa;
    padding: 55px 80px;
  }

  .row {
    width: 100%;
  }

  .align-self-start {
    align-self: flex-start;
  }

  .section-title {
    font-size: 30px;
    padding: 3px 20px;
    margin-top: 75px;
    margin-bottom: 45px;
    color: white;
    font-weight: bold;

    &:first-child {
      margin-top: 16px;
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;
    margin-left: 17px;

    &.no-bullets {
      li:before {
        display: none;
      }
    }

    &.no-margin {
      margin-left: 0;
    }
  }

  li {
    line-height: 2;
    margin-top: 10px;
    font-size: 14px;
    color: inherit;
    border: none;
    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: '';
      background: #0093ff;
      border-radius: 50%;
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-left: -15px;
      margin-right: 10px;
    }
  }

  @media (max-width: 992px) {
    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
      margin-bottom: 14px;
    }

    &.bg {
      margin-top: 10px;
      border-radius: 24px;
      padding: 40px 40px;
    }
  }

  @media (max-width: 600px) {
    &.bg {
      margin-top: 20px;
      padding: 30px;
    }

    h2 {
      margin-top: 34px;
      margin-bottom: 14px;
    }

    h3 {
      margin-bottom: 10px;
    }

    h2 + h3 {
      margin-top: -2px;
    }
  }
}

.page-main-title {
  margin-bottom: 40px;
  h1,
  h2 {
    font-size: 40px;
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    font-size: 14px;
    font-weight: 100;
    text-align: center;
  }

  @media (max-width: 991px) {
    h1,
    h2 {
      font-size: 30px;
    }
  }
}
