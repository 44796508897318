.app-footer {
  background: #fff;
  border-top: 1px solid rgba(199, 199, 199, 0.5);
  //background: #C7C7C7;

  .list-title {
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 24px;

    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #1b233d;
  }

  dt {
    color: #1b233d;
  }

  dd {
    margin-bottom: 12px;
  }

  hr {
    color: #c7c7c7;
    margin: 0;
    opacity: 0.5;
  }

  nav {
    flex-grow: 1;
    a,
    .footer-item {
      color: #8e909d;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }

    a:hover {
      color: #5e606d;
    }
  }

  .app-logo {
    flex-shrink: 0;
    width: 20%;
  }

  .rights {
    font-weight: 400;
    font-size: 12px;
    padding: 20px 0;
    color: rgba(27, 35, 61, 0.4);
  }

  @media (max-width: 992px) {
    .footer-main-content {
      flex-direction: column;
    }
    .app-logo {
      width: 100%;
    }

    nav {
      margin-top: 30px;
      justify-content: space-between !important;

      dl {
        .list-title {
          margin-bottom: 18px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    nav {
      flex-wrap: wrap;
      dl {
        width: 50%;
      }
    }
  }

  @media (max-width: 600px) {
    .footer-main-content {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }
  }
}
