@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap');
@import './assets/styles/fonts/AllRoundGothicW01-Bold.scss';

@import './assets/styles/base';
@import './assets/styles/typo';
@import './assets/styles/buttons';
@import './assets/styles/animations';
@import './assets/styles/pages/home';
@import './assets/styles/pages/contact-us';
@import './assets/styles/pages/file-info';
@import './assets/styles/pages/how-it-works';
@import './assets/styles/pages/converter';
@import './assets/styles/pages/converters-list';
@import './assets/styles/pages/applications';
@import './assets/styles/components/list';
