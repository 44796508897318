.home-page {
  main {
    padding-bottom: 250px;
    padding-top: 70px;
    //margin-top: -180px;

    //background: url(../../images/grid.svg) -13% 70% no-repeat,
    //url(../../images/mix.svg) right top no-repeat,
    //url(../../images/grid.svg) 114% bottom no-repeat;
    //background-size: 15%, 50%, 15%;

    background: url(../../images/grid.svg) -150px 350px no-repeat,
      url(../../images/mix2.svg) calc(50% + 450px) calc(50% - 50px) no-repeat,
      url(../../images/grid.svg) calc(50% + 650px) calc(100% - 20px) no-repeat;
    background-size: 330px, 900px, 330px;

    //.home-page main {
    //  background: url(/static/media/grid.136a9256d16888900db0.svg) -150px 350px no-repeat,
    //  url(/static/media/mix2.03c3c28e29dfd8dab593.svg) calc(50% + 450px) 0px no-repeat,
    //  url(/static/media/grid.136a9256d16888900db0.svg) calc(50% + 750px) 100% no-repeat;

    h1 {
      font-size: 48px;

      &.high {
        font-size: 56px;
      }

      .star {
        zoom: 0.8;
        margin-left: 30px;
        margin-top: -10px;
      }
    }

    p {
      font-size: 16px;
      line-height: 1.8;
    }

    .platforms {
      margin-left: 30px;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        line-height: 160%;
        margin-right: 10px;
        white-space: nowrap;
      }

      .platform-item {
        padding: 5px;
        margin: 0 2px;
        display: inline-flex;

        img {
          height: 20px;
          width: auto;
        }

        .icon {
          position: relative;
          margin: 0;
          display: inline-flex;
          &:after {
            transition: 0.15s ease-in-out;
            opacity: 0;
          }
        }

        &.facebook {
          .icon {
            &:after {
              content: url('/assets/images/canvas/opera.svg');
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: inline-flex;
            }
          }
        }

        &.edge {
          .icon {
            &:after {
              content: url('/assets/images/canvas/edge.svg');
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: inline-flex;
            }
          }
        }

        &.twitch {
          .icon {
            &:after {
              content: url('/assets/images/firefox.svg');
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: inline-flex;
            }
          }
        }

        &:hover {
          .icon {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    @media (max-width: 1600px) {
      background: url(../../images/grid.svg) -180px 350px no-repeat,
        url(../../images/mix2.svg) calc(50% + 450px) calc(50% - 50px) no-repeat,
        url(../../images/grid.svg) calc(50% + 750px) calc(100% - 20px) no-repeat;
      background-size: 270px, 900px, 310px;
    }

    @media (max-width: 1400px) {
      background: url(../../images/grid.svg) -170px 400px no-repeat,
        url(../../images/mix2.svg) calc(50% + 450px) calc(50% - 50px) no-repeat,
        url(../../images/grid.svg) calc(50% + 550px) calc(100% - 20px) no-repeat;
      background-size: 210px, 900px, 210px;
    }

    @media (max-width: 1120px) {
      h1 {
        .star {
          display: none;
        }
      }
    }

    @media (max-width: 992px) {
      padding-bottom: 130px;
      background: url(../../images/grid.svg) -170px 400px no-repeat,
        url(../../images/mix2.svg) calc(50% + 460px) calc(50% + 20px) no-repeat,
        url(../../images/grid.svg) calc(100% + 120px) calc(100% - 20px) no-repeat;
      background-size: 210px, 800px, 210px;

      h1 {
        font-size: 38px;
        line-height: 45px;

        &.high {
          font-size: 46px;
        }
      }
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 32px;
        line-height: 42px;

        &.high {
          font-size: 36px;
        }
      }
    }

    @media (max-width: 600px) {
      background: url(../../images/grid.svg) calc(100% + 120px) calc(100% - 20px) no-repeat;
      background-size: 190px, 210px;
      padding-bottom: 80px;

      .main-text-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      h1 {
        font-size: 38px;
        line-height: 45px;
        text-align: center;

        &.high {
          font-size: 46px;
        }
      }

      p {
        text-align: center;
      }
    }
  }

  .section-title {
    color: #1b233d;
    font-size: 34px;
    line-height: 1.5;

    @media (max-width: 992px) {
      font-size: 28px;
    }
  }

  .section-description {
    margin-top: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8;
    color: #1b233d;
    opacity: 0.7;
  }

  .features-section {
    position: relative;
    .features-tabs {
      margin-top: 50px;
      background: #ffffff;
      box-shadow: 15px 10px 80px rgba(27, 35, 61, 0.07);
      border-radius: 50px;
      .features-tab-item {
        position: relative;
        padding: 50px;
        h5 {
          margin-top: -20px;
          margin-bottom: 0;
          font-weight: 700;
          font-size: 20px;
          line-height: 29px;
          text-align: center;
          color: #1b233d;
        }

        p {
          margin-top: 16px;
        }

        img {
          height: 150px;
          width: auto;
          margin-right: -24px;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          opacity: 0.25;
          border-radius: 1px;
          width: 1px;
          background: #1b233d;
          height: 30%;
        }

        &:first-child {
          &:after {
            display: none;
          }
        }
      }
    }

    @media (max-width: 992px) {
      .features-tabs {
        padding: 50px 30px 20px;
        .features-tab-item {
          padding: 0;
          flex-direction: row !important;
          align-items: center;

          .image-wrapper {
            img {
              margin-top: -20px;
            }
          }

          .text-wrapper {
            padding-left: 10px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            height: 100%;

            p {
              margin-top: 10px;
              text-align: left !important;
            }
          }

          &:after {
            display: none;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .features-tabs {
        padding: 50px 40px;
        padding-bottom: 20px;

        .features-tab-item {
        }
      }
    }

    @media (max-width: 650px) {
      .features-tabs {
        padding: 0px;
        .features-tab-item {
          padding: 20px;
          padding-top: 40px;
          flex-direction: column !important;
          align-items: center;
          justify-content: center;

          .text-wrapper {
            align-items: center;
            h5 {
              text-align: center !important;
            }
            p {
              text-align: center !important;
            }
          }

          &:after {
            content: '';
            position: absolute;
            display: block;
            left: 35%;
            top: 0;
            opacity: 0.25;
            border-radius: 1px;
            width: 30%;
            background: #1b233d;
            height: 1px;
          }
        }
      }
    }
  }

  .how-it-works-section {
    background-color: #f7f8fa;
    padding-top: 1px;
    // margin-top: -160px;
    z-index: 1;
    padding-bottom: 40px;

    .section-title {
      margin-top: 100px;
    }

    h4 {
      margin-bottom: 50px;
      margin-top: 40px;
      font-size: 27.2px;

      @media (max-width: 992px) {
        font-size: 22px;
      }
    }

    .slider {
      margin-top: -40px;
      margin-left: -30px;
      padding-left: 30px;
      padding-right: 30px;
      width: calc(100% + 60px);

      .slider-item {
        padding: 48px;
        padding-bottom: 20px;
        align-items: center;

        img {
          margin-left: 17px;
          margin-top: -48px;
          height: auto;
          width: 290px;
        }

        h5 {
          margin-top: -20px;

          font-weight: 700;
          font-size: 20px;
          line-height: 29px;
          text-align: center;
          color: #1b233d;
          margin-bottom: 16px;
        }

        p {
          margin: 0;
        }
      }

      .swiper-slide {
        //width: 300px;
      }

      .splide__track {
        //display: flex;
      }

      .swiper-pagination {
        display: none;
      }

      .splide__pagination {
        display: none;
      }
    }

    @media (max-width: 1050px) {
      padding-top: 220px;
      margin-top: -220px;

      .slider {
        margin-left: -50px;
        width: calc(100% + 100px);
        .slider-item {
          width: 340px;
          padding-left: 10px;
          padding-right: 10px;

          p {
            max-width: 320px;
          }
        }
      }

      .swiper-slide {
        width: auto;
      }

      .splide {
        padding: 0;
      }

      .splide__track {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .splide__slide {
        //width: auto!important;
      }
    }
    @media (max-width: 600px) {
      padding-top: 420px;
      margin-top: -420px;

      .slider {
        margin-left: -45px;
        width: calc(100% + 90px);
        padding-left: 0;
        padding-right: 0;
        .slider-item {
          width: 100%;
          padding-left: 45px;
          padding-right: 45px;

          img {
            height: auto;
            margin-left: 20px;
            margin-top: -48px;
            width: 290px;
          }

          p {
            max-width: 320px;
          }
        }

        .swiper-slide {
          width: 100%;
        }

        .splide__slide {
          width: 100%;
        }

        .swiper-wrapper {
          padding-bottom: 30px;
        }

        .swiper-pagination {
          bottom: 0;
          display: block;

          .swiper-pagination-bullet {
            margin: 0 6px;
            width: 10px;
            height: 10px;
            background: #1b233d;
            opacity: 0.15;

            &.swiper-pagination-bullet-active {
              background: #0093ff;
              opacity: 1;
            }
          }
        }

        .splide__pagination {
          display: flex;
          .splide__pagination__page {
            margin: 0 6px;
            width: 10px;
            height: 10px;
            background: #1b233d;
            opacity: 0.15;

            &.is-active {
              background: #0093ff;
              opacity: 1;
              transform: none;
            }
          }
        }
      }

      .splide {
        padding-bottom: 30px;
      }
    }
  }

  .app-platform-circles {
    height: 600px;
    overflow: hidden;
    position: relative;
    .canvas {
      margin-top: -200px;
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      $bg: white;
      height: 100%;
      background: $bg;

      .circles-container {
        margin-top: -280px;
        display: grid;
        align-items: center;
        justify-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .app-logo {
          width: 130px;
          height: auto;
          grid-area: 1 / 1;
          position: relative;
        }

        .app-logo-shadow {
          width: 200px;
          height: auto;
          grid-area: 1 / 1;
          position: relative;
          margin-bottom: -10px;
        }

        .circle {
          grid-area: 1 / 1;
          width: 330px;
          height: 330px;
          //background: #1a73e8;
          position: relative;
          border-radius: 50%;
          background: inherit;
          background: conic-gradient(
            from 180deg at 50% 50%,
            #ffa246 0deg,
            #ff5c43 40.8deg,
            #ff4995 71.49deg,
            #cd2a9d 141.05deg,
            #ff4995 210.06deg,
            #ff5c43 227.66deg,
            #ffa246 252.51deg,
            #fee48e 297.96deg,
            #ffa246 360deg
          );

          &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
            border-radius: inherit;
            background: $bg;
          }

          &.circle-1 {
            width: 350px;
            height: 350px;
            opacity: 0.25;
          }
          &.circle-2 {
            width: 552px;
            height: 552px;
            opacity: 0.2;
          }
          &.circle-3 {
            width: 768px;
            height: 768px;
            opacity: 0.15;
          }
          &.circle-4 {
            width: 1084px;
            height: 1084px;
            opacity: 0.1;
          }
          &.circle-5 {
            width: 1440px;
            height: 1440px;
            opacity: 0.05;
          }
        }
      }

      .platform {
        position: absolute;
        height: 118px;
        width: 118px;
        top: 0;
        left: 0;
        background: #ffffff;
        box-shadow: 20px 20px 100px rgba(0, 0, 0, 0.05);
        border-radius: 32px;

        img {
          height: 67px;
          width: auto;
        }

        &.facebook {
          top: 321px;
          left: calc(50% - 575px);
        }

        &.hangouts {
          height: 130px;
          width: 130px;
          top: 255px;
          left: calc(50% - 355px);
        }

        &.twitch {
          height: 125px;
          width: 125px;
          top: 588px;
          left: calc(50% + 515px);
        }

        &.chrome {
          top: 452px;
          height: 125px;
          width: 125px;
          left: calc(50% + 255px);
        }

        &.youtube {
          top: 244px;
          height: 117px;
          width: 117px;
          left: calc(50% + 215px);

          img {
            height: 44px;
          }
        }

        &.edge {
          height: 120px;
          width: 120px;
          top: 323px;
          left: calc(50% + 555px);
        }

        &.twitter {
          top: 518px;
          left: calc(50% - 395px);

          img {
            height: 52px;
          }
        }

        &.zoom {
          top: 577px;
          left: calc(50% - 670px);

          img {
            height: 64px;
          }
        }
      }

      @media (max-width: 1370px) {
        .platform {
          &.facebook {
            top: 321px;
            left: calc(50% - 475px);
          }

          &.hangouts {
            top: 255px;
            left: calc(50% - 285px);
          }

          &.twitch {
            top: 628px;
            left: calc(50% + 385px);
          }

          &.chrome {
            top: 452px;
            left: calc(50% + 205px);
          }

          &.youtube {
            top: 244px;
            left: calc(50% + 215px);
          }

          &.edge {
            top: 323px;
            left: calc(50% + 415px);
          }

          &.twitter {
            top: 498px;
            left: calc(50% - 395px);
          }

          &.zoom {
            top: 667px;
            left: calc(50% - 470px);
          }
        }
      }

      @media (max-width: 1115px) {
        .platform {
          box-shadow: 9.74895px 9.74895px 48.7448px rgba(0, 0, 0, 0.05);
          border-radius: 15.5983px;
          &.facebook {
            width: 57.5px;
            height: 57.5px;
            top: 371px;
            left: calc(50% - 285px);
            img {
              height: 33px;
            }
          }

          &.hangouts {
            width: 63.5px;
            height: 63.5px;
            top: 335px;
            left: calc(50% - 165px);
            img {
              height: 32px;
            }
          }

          &.twitch {
            width: 61px;
            height: 61px;
            top: 528px;
            left: calc(50% + 245px);
            img {
              height: 32.5px;
            }
          }

          &.chrome {
            width: 61px;
            height: 61px;
            top: 442px;
            left: calc(50% + 145px);
            img {
              height: 35px;
            }
          }

          &.youtube {
            width: 57.5px;
            height: 57.5px;
            top: 324px;
            left: calc(50% + 115px);
            img {
              height: 22px;
            }
          }

          &.edge {
            width: 58.5px;
            height: 58.5px;
            top: 343px;
            left: calc(50% + 215px);

            img {
              height: 31px;
            }
          }

          &.twitter {
            width: 58px;
            height: 58px;
            top: 478px;
            left: calc(50% - 215px);

            img {
              height: 24px;
            }
          }

          &.zoom {
            width: 57.5px;
            height: 57.5px;
            top: 497px;
            left: calc(50% - 310px);

            img {
              height: 28px;
            }
          }
        }
      }

      @media (max-width: 992px) {
        .circles-container {
          margin-top: -90px;
          .app-logo {
            width: 120px;
          }

          .app-logo-shadow {
            width: 185px;
          }

          .circle {
            &.circle-1 {
              width: 248px;
              height: 248px;
            }
            &.circle-2 {
              width: 372px;
              height: 372px;
            }
            &.circle-3 {
              width: 546px;
              height: 546px;
            }
            &.circle-4 {
              width: 770px;
              height: 770px;
            }
            &.circle-5 {
              width: 1084px;
              height: 1084px;
            }
          }
        }
      }

      @media (max-width: 620px) {
        .circles-container {
          margin-top: 0px;
          .circle {
            &.circle-1 {
              width: 230px;
              height: 230px;
            }
            &.circle-2 {
              width: 343px;
              height: 343px;
            }
            &.circle-3 {
              width: 505px;
              height: 505px;
            }
            &.circle-4 {
              width: 670px;
              height: 670px;
            }
            &.circle-5 {
              width: 884px;
              height: 884px;
            }
          }
        }

        .platform {
          display: none !important;
          &.facebook {
            top: 342px;
            left: calc(50% - 155px);
          }

          &.hangouts {
            top: 260px;
            left: calc(50% - 85px);
          }

          &.twitch {
            top: 508px;
            left: calc(50% + 60px);
          }

          &.chrome {
            top: 342px;
            left: calc(50% + 75px);
          }

          &.youtube {
            top: 264px;
            left: calc(50% + 35px);
          }

          &.edge {
            top: 435px;
            left: calc(50% + 105px);
          }

          &.twitter {
            top: 518px;
            left: calc(50% - 115px);
          }

          &.zoom {
            top: 435px;
            left: calc(50% - 170px);
          }
        }
      }
    }

    .description-section {
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      margin-top: 110px;
      h4 {
        font-weight: 700;
        font-size: 32px;
        line-height: 1.4;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 160%;
      }

      .btn-install {
        margin-top: 15px;
      }

      @media (max-width: 992px) {
        h4 {
          font-size: 28px;
        }
      }

      @media (max-width: 620px) {
        margin-top: 110px;
      }
    }
  }
}
