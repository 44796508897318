.windows-banner {
  width: 100%;
  height: 175px;
  display: block;
  margin: 50px 0;
  border-radius: 20px;
  background: #f8fafb;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  text-align: center;

  .banner-content {
    padding: 30px 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .windows-icon {
      /* background: #fff; */
      border-radius: 50%;
      /* box-shadow: 0 0 18px 4px rgba(0,0,0,.02); */
      width: 60px;
      margin-right: -15px;
    }

    & > div {
      text-align: left;

      p {
        color: #2c3033;
        font-size: 30px;
        font-weight: 600;
        line-height: 1;
        margin: 0;

        &:first-child {
          font-weight: 300;
        }

        b {
          color: #0093ff;
          font-weight: 600;
        }
      }
    }
  }

  &:before {
    height: 100%;
    width: 202px;
    background: url(../../images/banner-left.png) no-repeat;
    background-size: cover;
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
  }

  &:after {
    height: 100%;
    width: 202px;
    background: url(../../images/banner-right.png) no-repeat;
    background-size: cover;
    display: block;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
  }

  a.button-download {
    background: #0093ff;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 40px;
    text-decoration: none;
    transition: all 0.5s ease;

    &:hover {
      color: white;
      background: #0b76c6;
      transition: all 0.5s ease;
    }
  }
}
