body {
  margin: 0;
  font-family: 'DM Sans', 'Open Sans', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, sans-sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
}

.container {
  max-width: 1236px;
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: 992px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 600px) {
    padding-left: 45px;
    padding-right: 45px;
  }

  @media (max-width: 440px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 380px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

:focus {
  outline: none;
}

a {
  color: #0093ff;
}

a.grayscale {
  color: #c0c0c0;
}

a.grayscale:hover {
  color: #b0b0b0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'AllRoundGothicW01-Bold', sans-sans;
}

.form-control:focus,
.form-control:focus-visible {
  outline: none;
  color: #212529;
  background-color: #fff;
  border-color: #b7b7b7;
  box-shadow: 0 0 0 0.25rem #e7e8ea;
}

.rounded-4 {
  border-radius: 0.5rem;
}
.rounded-5 {
  border-radius: 12px;
}
.rounded-6 {
  border-radius: 2rem;
}

.text-muted-3 {
  color: #7e708d;
}
.text-muted-0 {
  color: #696b75;
}
.text-muted-1 {
  color: #aeb0bd;
}
.fc-primary {
  color: #0093ff;
}
.m-6 {
  margin: 6rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}
.mb-6 {
  margin-bottom: 6rem !important;
}
.mb-5 {
  margin-bottom: 80px !important;
}

.p-6 {
  padding: 6rem !important;
}
.pt-6 {
  padding-top: 6rem !important;
}
.pb-6 {
  padding-bottom: 6rem !important;
}
.pb-7 {
  padding-bottom: 12rem !important;
}

.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 4rem !important;
}

.ads {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 90px;
    width: auto;
  }
}
