@import './../mixins';

.applications-page {
  .apps-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;

    .application-item {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(27, 35, 61, 0.05);
      border-radius: 32px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      padding: 15px;
      @include tr;

      img {
        width: 100%;
        height: auto;
        border-radius: 21px;
      }

      &:hover {
        background-color: #ffffff;
        box-shadow: 0px 0px 180px rgba(27, 35, 61, 0.05);
        border-color: transparent;
      }
    }

    @media (max-width: 1100px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px !important;
  }

  @media (max-width: 600px) {
    margin-top: 60px !important;
  }
}
