.collapsable-section {
  background: #ffffff;
  box-shadow: 0px 20px 50px rgba(27, 35, 61, 0.05);
  border-radius: 20px;
  padding: 28px 40px;
  position: relative;
  cursor: pointer;

  .collapse-btn {
    width: 40px;
    height: 40px;
    position: absolute;

    background: #ffffff;
    box-shadow: 7px 7px 20px rgba(27, 35, 61, 0.1);
    border-radius: 48px;
    right: 30px;
    top: 26px;
    display: grid;
    align-content: center;
    justify-items: center;

    .line {
      grid-area: 1 / 1;
      height: 2.5px;
      background: #1b233d;
      width: 14px;
      border-radius: 3px;

      &.line-2 {
        transform: rotate(90deg);
      }
    }
  }

  .title {
    font-size: 20px;
    margin: 0;
    padding-right: 80px;
  }

  .collapse {
    display: none;
    padding-top: 20px;
    font-size: 16px;
    padding-right: 80px;
  }

  &.open {
    .collapse {
      display: block;
    }
    .collapse-btn {
      .line {
        background: #0093ff;
      }
      .line-2 {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    padding: 20px 30px;
    .title {
      font-size: 18px;
    }

    .collapse-btn {
      width: 32px;
      height: 32px;
      right: 24px;
      top: 20px;

      .line {
        width: 11px;
      }
    }

    .collapse {
      padding-top: 12px;
    }
  }

  @media (max-width: 600px) {
    .title {
      padding-right: 40px;
    }

    .collapse {
      padding-right: 40px;
    }
  }
}
