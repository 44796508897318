@import './../mixins';

.converters-list-page {
  .banner-gap {
    width: 728px;
    height: 90px;
    padding: 10px;
    box-sizing: border-box;

    background: #ffffff;
    box-shadow: 60px 60px 180px rgba(27, 35, 61, 0.07);
    border-radius: 10px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      opacity: 0.25;
      border: 1px dashed #1b233d;
      filter: drop-shadow(60px 60px 180px rgba(27, 35, 61, 0.07));
      border-radius: 10px;

      color: #1b233d;
      opacity: 0.25;
    }
  }

  .page-main-title {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 20px;
    }

    .search-input {
      margin-top: 0px;
      border: 1px solid rgba(27, 35, 61, 0.05);
      border-radius: 10px;
      font-size: 16px;
      background: #f7f8fa no-repeat;
      background-image: url(../../images/search-icon.svg);
      background-position: 18px center;
      background-size: 18px;
      max-width: 420px;
      width: 100%;
      padding: 16px 12px 16px 50px;

      &::placeholder {
        color: #1b233d;
        opacity: 0.25;
      }
    }

    .ads {
      margin-top: 40px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px !important;
  }

  @media (max-width: 600px) {
    margin-top: 60px !important;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  margin-top: 40px;
}

.formats-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  margin-top: 40px;
}

.grid-card {
  position: relative;
  padding: 0.8rem;
  border: 1px solid rgba(27, 35, 61, 0.05);
  border-radius: 2rem;
  cursor: pointer;

  .grid-card-thumbnail-wrapper {
    border-radius: 1rem;
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.1);
      height: 100%;
      width: 100%;
      transition: 0.2s ease;
      opacity: 0;
    }
  }

  .grid-card-thumbnail {
    background-color: #fff;
    max-width: 100%;
    width: 100%;
    // height: auto;
    aspect-ratio: 4/2;
    border-radius: 1rem;
    transition: 0.2s ease;
  }
  .grid-card-action {
    position: absolute;
    bottom: 1.4rem;
    right: 1.4rem;
    height: 36px;

    border-radius: 0.4rem;
    background-color: #0093ff;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;

    span {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      color: white;
      font-size: 12px;
    }

    img {
      height: 13px;
      width: auto;
      margin: 0.25rem;
      display: flex;
    }
  }
  .grid-card-action:hover {
    background-color: lighten(#0093ff, 8%);
  }
  .grid-card-action:active {
    background-color: lighten(#0093ff, 12%);
  }

  &:hover {
    .grid-card-action {
      opacity: 1;
      visibility: visible;
    }

    .grid-card-thumbnail-wrapper {
      &:after {
        opacity: 1;
      }
    }

    .grid-card-thumbnail {
      transform: scale(1.04);
    }
  }
}

.format-card {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(27, 35, 61, 0.05);
  border-radius: 32px;
  padding: 34px;
  padding-bottom: 24px;
  @include tr;

  .format-card-title {
    font-weight: 700;
    font-size: 24px;
    display: flex;
    align-items: center;
    .letter {
      background: #ffffff;
      box-shadow: 8px 8px 30px rgba(27, 35, 61, 0.07);
      border-radius: 16px;
      padding: 6px;
      width: 60px;
      height: 60px;
      color: #0093ff;
      cursor: default;
      div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        box-shadow: 8px 8px 30px rgba(27, 35, 61, 0.07);
        border-radius: 12px;
      }
    }

    span {
      margin: 0;
      margin-left: 24px;
    }
  }

  ul {
    margin: 0;
    margin-top: 40px;
    list-style: none;
    width: 100%;
    padding: 0;

    li {
      font-weight: 500;
      font-size: 18px;
      width: 100%;
      color: #1b233d;
      line-height: 2;
      border-bottom: 1px solid rgba(27, 35, 61, 0.05);
      display: flex;
      padding: 0;
      &:first-child {
        margin-top: 0;
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;
        position: relative;
        padding: 7px 7px 7px 18px;
        font-size: 16px;
        font-weight: 100;

        &::before {
          content: '';
          background: #0093ff;
          border-radius: 50%;
          display: inline-block;
          width: 6px;
          height: 6px;
          margin-left: -18px;
          margin-right: 10px;
        }

        &::after {
          content: '';
          background: url(../../images/right-arrow.svg) no-repeat center;
          position: absolute;
          background-size: 12px;
          right: 0;
          top: 0;
          width: 20px;
          height: 100%;
          display: flex;
          align-items: center;
          //
          //margin-left: -15px;
          //margin-right: 10px;
        }

        &:hover {
          color: #0093ff;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &:hover {
    background: #ffffff;
    box-shadow: 0px 0px 180px rgba(27, 35, 61, 0.05);
    border-color: transparent;
  }

  @media (max-width: 600px) {
    padding: 26px;
    border-radius: 24px;
  }
}

@media (max-width: 1100px) {
  .formats-container {
    grid-template-columns: 1fr 1fr;
  }
  .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .formats-container {
    grid-template-columns: 1fr;
  }
  .grid-container {
    grid-template-columns: 1fr;
  }
}
