@import './../mixins';

.app-header {
  //nav a.active::after {
  //  content: '';
  //  border-bottom: 3px solid #e7e8ea;
  //  width: 100%;
  //  height: 1px;
  //  font-weight: 500;
  //}
  padding: 35px 0 60px;

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .burger-btn {
    display: none;
  }

  nav {
    a {
      font-size: 14px;
      padding: 6px 12px;
      margin: 0 15px;
      color: #2b334d;
      text-decoration: none;
      user-select: none;

      &:hover {
        color: #111;
      }

      &.active {
        color: #2da6ff;
        font-weight: 700;
        pointer-events: none;
      }

      &.mobile {
        display: none !important;
      }
    }
  }

  @media (max-width: 900px) {
    nav {
      a {
        padding: 6px 8px;
        margin: 0 10px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0 40px;
    nav {
      a {
        padding: 6px 8px;
        margin: 0 10px;
      }
    }
  }

  @media (max-width: 660px) {
    nav {
      a {
        padding: 3px 8px;
        margin: 0 6px;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0;
    height: 76px;
    .wrapper {
      position: fixed;
      background: white;
      z-index: 10000;
      left: 0;
      right: 0;
      top: 0;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .container {
        height: 100%;
        padding-right: 0;
      }
    }

    nav {
      display: none !important;
    }

    .burger-btn {
      display: grid;
      flex-direction: column;
      height: 100%;
      width: 76px;
      align-items: center;
      justify-content: center;
      position: relative;

      .burger-icon,
      .burger-close-icon {
        @include tr();
        width: 24px;
        height: 24px;
        grid-area: 1 / 1;
      }

      .burger-close-icon {
        opacity: 0;
        transform: scale(0.6);
      }
    }

    &.open {
      .burger-icon {
        opacity: 0;
        transform: scale(0.6);
      }
      .burger-close-icon {
        opacity: 1;
        transform: scale(1);
      }

      nav {
        display: flex !important;
        position: fixed;
        top: 76px;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10000;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background: url(../../images/grid.svg) -110px calc(100% - 100px) no-repeat,
          url(../../images/grid.svg) calc(100% + 120px) 120px no-repeat;
        background-size: 210px, 210px;
        background-color: white;

        a {
          padding: 6px 8px;
          margin: 0 10px;
          margin-top: 50px;

          font-weight: 700;
          font-size: 22px;

          &:first-child {
            margin-top: -38px;
          }

          &.mobile {
            display: flex !important;
          }
        }
      }
    }
  }
}
