.contact-us-page {
  .contact-form {
    h1 {
      padding-left: 102px;
      position: relative;
      margin-bottom: 10px;
      img {
        left: 0;
        top: -16px;
        margin-left: -32px;
        position: absolute;
      }
    }

    p {
      font-size: 16px;
      color: #1b233d;
      opacity: 0.7;
    }

    input,
    textarea {
      color: #1b233d;
      background: #ffffff;
      border: 1px solid rgba(27, 35, 61, 0.15);
      border-radius: 12px;
      &::placeholder {
        color: #1b233d;
        opacity: 0.25;
      }
    }

    textarea {
      resize: none;
    }

    form {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      gap: 20px;

      .top-input-wrap {
        gap: inherit;
      }

      .submit-wrap {
        margin-top: 20px;
        margin-bottom: 30px;

        button {
          border-radius: 12px;
          font-size: 16px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    @media (max-width: 992px) {
      h1 {
        padding-left: 90px;
        img {
          height: 110px;
          top: -26px;
          margin-left: -26px;
        }
      }

      p {
        font-size: 16px;
      }

      form {
        margin-top: 20px;
      }
    }

    @media (max-width: 720px) {
      form {
        .submit-wrap {
          margin-top: 0px;
          flex-direction: column;
          margin-bottom: 0;

          button {
            width: 100%;
            margin-right: 0 !important;
          }

          p {
            margin-top: 20px !important;
          }
        }
      }
    }

    @media (max-width: 600px) {
      h1 {
        padding-left: 0;
        padding-top: 60px;

        img {
          top: -21px;
        }
      }
      form {
        .top-input-wrap {
          flex-direction: column !important;
        }
      }
    }
  }
}
