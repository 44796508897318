.main-section {
  padding-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
  .promo-image {
    position: relative;
    img {
      width: 539px;
    }
    &:after {
      content: '|';
      color: white;
      top: 99px;
      left: 293px;
      display: block;
      position: absolute;
      font-size: 48px;
      animation: blinking 1s infinite;
    }
    @keyframes blinking {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
      width: 50%;
      &:nth-child(2) {
        padding-bottom: 30px;
      }
    }
  }
  .main-screenshot {
    margin-top: 100px;
    background: #3287ff;
    border-radius: 45px;
    min-height: 400px;
  }
  h1 {
    text-align: left;
    font-size: 55px;
    line-height: 1;
    margin-bottom: 20px;
    b {
      color: #0093ff;
    }
  }
  p {
    font-size: 16px;
    text-align: left;
    margin: 0 auto 30px auto;
  }
  &:after {
    content: '';
    width: 115px;
    height: 190px;
    display: block;
    left: 0;
    position: absolute;
    bottom: 5px;
    background: url(../../images/bg-circle.png);
  }
  &:before {
    content: '';
    width: 240px;
    height: 240px;
    display: block;
    right: 0;
    position: absolute;
    top: 100px;
    background: url(../../images/bg-triangle.png);
  }
}

footer {
  z-index: 9;
}

.bg-wrap {
  background-image: url(../../images/main-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  top: 0;
}

#root .app-header {
  padding: 35px 0 35px;
}

.windows-page {
  background: white;
  .btn-primary {
    padding: 0.765rem 0.95rem;
    &:hover {
      box-shadow: 0.5rem 0.5rem 1rem rgb(26 115 232 / 15%);
    }
    img:first-child {
      width: 35px;
      margin-right: 10px;
    }
    img:last-child {
      margin-left: 10px;
    }
  }
}

.promo-users {
  background: #f3f7fd;
  padding-top: 120px;
  margin-top: -100px;
  padding-bottom: 100px;
  h3 {
    text-align: center;
    margin-bottom: 50px;
    font-size: 36px;
    b {
      color: #0093ff;
    }
  }
  .promo-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    & > div {
      box-shadow: 0 19px 10px 7px rgba(65, 106, 255, 0.04);
      img {
        width: 70px;
        margin-bottom: 20px;
      }
      text-align: center;
      width: 23%;
      margin-bottom: 20px;
      border-radius: 20px;
      background: white;
      transition: all 0.5s ease;
      padding: 30px;
      &:hover {
        transition: all 0.5s ease;
        box-shadow: none;
        transform: translateY(-5px);
      }
    }
  }
}

.promo-features {
  padding-top: 30px;
  padding-bottom: 20px;
  h3 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
  }
  .container {
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        padding: 30px;
        box-sizing: border-box;
        h4 {
          color: #0093ff;
        }
        width: 50%;
        &.small-img {
          img {
            width: 70% !important;
            margin: 0 auto 0 auto;
            display: table;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

.promo-banner-section {
  background: #f3f7fd;
  padding: 50px 0 50px 0;
  .container {
    padding: 30px 70px 30px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      width: 60%;
      &:first-child {
        background: white;
        padding: 40px;
        box-shadow: 0 21px 18px 4px #23394808;
        border-radius: 50px;
        margin-right: 60px;
        box-sizing: border-box;
        display: block;
        img {
          width: 100%;
        }
        width: 20%;
      }
    }
  }
  h3 {
    font-size: 42px;
    line-height: 1;
    b {
      color: #0093ff;
    }
  }
}

.windows-page main {
  margin-bottom: 0 !important;
}
