.btn-rounded {
  height: 54px;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid #e7e8ea;
  border-radius: 12px;
  padding: 0 20px;
  white-space: nowrap;

  &:hover {
    color: #111;
    /* filter: drop-shadow(0.25rem 0.25rem 1rem #eee); */
    background: #fff;
    box-shadow: 0.25rem 0.25rem 1rem #eee;
    box-shadow: 8px 8px 16px 0px #eee;
  }

  &:focus {
    box-shadow: 0.25rem 0.25rem 1.5rem #ddd;
    box-shadow: 4px 4px 24px 0px #ddd;
  }
  &:active {
    box-shadow: none !important;
  }

  // &:hover img {
  //   src: url('');
  // }
}

.btn-install {
  height: 54px;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 24px;
    width: auto;
    margin-right: 10px;
  }

  .arrow {
    height: 12px;
    width: auto;
    margin-left: 20px;
  }

  &.style-2 {
    padding: 0 28px;
  }
}

.windows-app-btn {
  height: 54px;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  background-color: #d645ad;
  border: 1px solid #bc50a2;
  color: white;

  img {
    height: 24px;
    width: auto;
    margin-right: 10px;
    border-radius: 6px;
  }

  &:hover {
    color: #fff;
    background-color: darken(#d645ad, 6%);
    box-shadow: 0.5rem 0.5rem 1rem rgb(189 61 153 / 25%);
  }
  &:focus {
    box-shadow: 0.5rem 0.5rem 1rem rgb(189 61 153 / 50%), 0 1px 3px 1px rgb(189 61 153 / 15%) !important;
  }
  &:active {
    box-shadow: none !important;
  }
}

.btn-install-light {
  height: 54px;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;

  img {
    height: 24px;
    width: auto;
    margin-right: 10px;
  }

  .arrow {
    height: 12px;
    width: auto;
    margin-left: 20px;
  }
}

.btn-primary {
  height: 58px;
  background-color: #0093ff;
  border: none;
  font-size: 14px;
  font-weight: 500;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0073df;
  border-color: #0063cf;
  box-shadow: 0.5rem 0.5rem 1rem rgb(26 115 232 / 25%);
}
.btn-primary:focus {
  box-shadow: 0.5rem 0.5rem 1rem rgb(26 115 232 / 50%), 0 1px 3px 1px rgb(26 115 232 / 15%) !important;
}

.btn-primary:active {
  box-shadow: none !important;
}
.btn-primary:disabled {
  color: #fff;
  background-color: #0093ff;
  border-color: #0063cf;
}
