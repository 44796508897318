$padding-side: 60px;
.container-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-image: url('./../assets/images/bg.png');
  background-size: cover;
  background-color: #ffffff;
  display: flex;
  min-height: 100%;
  height: 100%;
  flex-direction: column;
  // font-family: 'Didact Gothic';
  .header {
    height: 67px;
    background: #fafafa;
    border-bottom: 1px solid #e4e4e4;
    box-sizing: border-box;
    .cont {
      // max-width: 134rem;
      width: 100%;
      padding: 0 30px;
      // box-sizing: border-box;
      height: 100%;
      max-width: 1400px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        background: unset;
        display: flex;
        align-items: center;
        text-decoration: none;
        // img {
        //   width: 26px;
        //   height: auto;
        // }
        span {
          font-size: 18px;
          // font-family: 'Montserrat', sans-serif;
          margin-left: 12px;
          margin-top: -2px;
          color: #6f81b7;
        }
      }

      .btns {
        display: flex;
        align-items: center;
        .btn {
          // font-family: 'Didact Gothic Regular', sans-serif;
          transition: 0.2s ease;
          border-radius: 4px;
          font-size: 12px;
          padding: 0px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          height: 36px;
        }
        .download {
          color: white;
          background-color: #1e83ff;
          margin-left: 10px;
          &:hover {
            background: lighten(#1e83ff, 6%);
          }
        }

        .install {
          background: #0097ff;
          &:hover {
            background: lighten(#0097ff, 6%);
          }
        }
      }
    }
  }

  .container-wrap {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .content {
    height: calc(100% - 67px);
    padding: 25px 30px;
    max-width: 1400px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;

    .wrap {
      height: 100%;

      .file-preview {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          border: 1px solid #f5f5f5;
        }

        iframe {
          width: 100%;
          height: 100%;
          border: none;
        }

        .unknown-file {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          .red {
            width: 100%;
            height: 100%;
            background: red;
          }
          img {
            height: 350px;
          }
        }

        &.entire {
          display: block;
          overflow-y: auto;
          img {
            box-sizing: border-box;
            max-height: unset;
          }
        }
      }
    }

    .notfound {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      img {
        height: 320px;
      }

      .label {
        margin-top: 50px;
        font-size: 34px;
        color: #2caafe;
        font-weight: bold;
        // font-family: 'Fedoka One';
      }
    }

    .links {
      display: flex;
      padding-top: 40px;
      justify-content: center;
      .download {
        // background-color: $c-mblue;
        background-color: #046ab1;
        border-color: #046ab1;
        color: white;
        padding: 10px 15px;
        display: block;
        margin-right: 30px;
        font-size: 14px;
        height: 20px;
        white-space: nowrap;
        width: 150px;
        text-align: center;
      }

      .qr {
        width: 150px;
        white-space: nowrap;
        background-color: #4b8351; //$c-green;
        border-color: #4b8351;
        color: white;
        display: block;
        padding: 10px 0px;
        font-size: 14px;
        display: block;
        height: 20px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  footer {
    .container-wrap {
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        li {
          a {
            color: #0066ff;
            padding: 30px 10px;
            display: block;
            font-size: 12px;
          }

          &:last-child {
            a {
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1090px) {
  .container {
    .content {
      padding: 30px;
      padding-top: 25px;
    }
  }
}

@media screen and (max-width: 920px) {
  .container {
    header {
      .container-wrap {
        .install {
          display: none;
        }
      }
    }

    .content {
      .notfound {
        img {
          height: 280px;
        }
        .label {
          font-size: 34px;
          margin-top: 30px;
        }
      }

      .links {
        flex-direction: column;
        align-items: center;

        .download {
          margin-right: 0px;
          margin-bottom: 15px;
        }

        .qr {
          padding: 10px 15px;
        }
      }
    }

    footer {
      .container-wrap {
        padding: 0 30px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .container {
    .content {
      padding: 0 30px;
      padding-top: 25px;

      .notfound {
        img {
          height: 190px;
        }
        .label {
          font-size: 28px;
          margin-top: 25px;
        }
      }
    }

    footer {
      .container-wrap {
        padding: 0 25px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    header {
      .container-wrap {
        padding: 0 15px;
        .btns {
          .install {
            padding: 8px 20px;
            display: none;
          }

          .download {
            margin-right: 0px;
          }
        }
      }
    }

    .content {
      padding-left: 15px;
      padding-right: 15px;

      .notfound {
        img {
          height: 125px;
        }
        .label {
          margin-top: 20px;
          font-size: 20px;
        }
      }
    }

    footer {
      .container-wrap {
        padding: 0 15px;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .container {
    header {
      .container-wrap {
        .btns {
        }
      }
    }
    .content {
    }
  }
}


.header-actions{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .iframe-container{
    height: 36px;
  }
}
